export const tranformValues = (items) =>
  items.reduce((acc, item, i) => {
    acc.push({...item, value: i + 1, label: item.title});
    return acc;
  }, []);

export const splitSlidesData = (slides, sliceCount) => {
  let sliceData = [];
  const residue =
    ((slides.length / sliceCount) ^ 0) === slides.length / sliceCount;
  const slidesCount = Math.floor(slides.length / sliceCount + (residue ? 0 : 1));
  if (slides.length) {
    for (let i = 0; i < slidesCount; i++) {
      sliceData = [
        ...sliceData,
        [...slides.slice(i * sliceCount, sliceCount * (i + 1))],
      ];
    }
    return sliceData;
  } else {
    return sliceData;
  }
};

export const prettyNum = (balance) => {
  if (balance === 0) {
    return '0.00'
  }

  if (balance) {
    const num = balance / 100
    if ((num ^ 0) === num) {
      return String(num + '.00')
    } else {
      return String(num.toFixed(2))
    }
  }
}