import React from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { MainWrapper } from './styles';

import PollOffer from 'components/Oferta';
import Poll from '../Poll';
import { useForm } from 'hooks/useForm';
import StartPoll from '../StartPoll';
import { authSelector } from 'redux/authReducer';

import { pollsSelector, getNewPoll } from 'redux/pollsReducer';
import { getInfo } from '../../redux/authReducer';
import { getCurrentPoll } from '../../redux/pollsReducer';
import { Heading, Lines, Footer } from './styles';
import { Box } from '@material-ui/core';
const Polls = ({ hidePoll }) => {
  const dispatch = useDispatch();
  const { form, handleInputChange } = useForm({ isAccepted: false });

  const {
    authInfo: { info },
    // status,
  } = useSelector(authSelector);

  React.useEffect(() => {
    dispatch(getInfo());
    dispatch(getCurrentPoll());
  }, [dispatch]);

  const [isPollStarted, setIsPollStarted] = React.useState(false);
  const [isOfferShown, setOfferVisibility] = React.useState(false);
  const {
    currentPoll: { data },
    currentQuestion,
  } = useSelector(pollsSelector);

  React.useEffect(() => {
    if (info && info.poll_sum) {
      setIsPollStarted(true);
    }
    // eslint-disable-next-line
  }, [info && info.poll_sum]);


  React.useEffect(() => {
    if (currentQuestion !== -1 && data && data.questions) {
      setIsPollStarted(true);
    }
    // eslint-disable-next-line
  }, [currentQuestion, data && data.questions]);


  // const hotkeys = info && info.hotkeys;
  // const handleKeyPress = (e) => {
  //   const {code} = e;
  //   const {save_record, pass_poll, collect} = hotkeys;
  //   if (!isRulesShown && !isOfertaShown && !isPollShown && !isRecordsShown) {
  //     //save record
  //     if (code === save_record[0]) {
  //       setIsPollStarted(false);
  //       showRecords();
  //     }
  //     //pass poll
  //     if (code === pass_poll[0]) {
  //       setIsPollStarted(false);
  //       showPoll();
  //     }
  //     //collect
  //     if (code === collect[0]) {
  //       info && info.balance > 0 && showCollect();
  //     }
  //   }
  // };

  // React.useEffect(() => {
  //   if (hotkeys) {
  //     document.addEventListener('keydown', handleKeyPress);

  //     return () => {
  //       document.removeEventListener('keydown', handleKeyPress);
  //     };
  //   }
  //   // eslint-disable-next-line  
  // }, [info, isRulesShown, isOfertaShown, isPollShown, isRecordsShown]);


  return (
    <MainWrapper>
      <Heading>
        <Lines />
        <div className="wrapper">Cоциальные опросы</div>
      </Heading>
      {info && (
        <Box>
          {isPollStarted ? <Poll hidePoll={hidePoll} /> :
            <StartPoll setOfferVisibility={setOfferVisibility}
              startPoll={() => { dispatch(getNewPoll()); setIsPollStarted(true); }}
              checked={form.isAccepted} onChange={handleInputChange} name="isAccepted" />}
        </Box>
      )}
      {isOfferShown && <PollOffer hideOferta={() => setOfferVisibility(false)} />}
      <Footer>
        <Box display='flex' flexDirection='column' alignItems='flex-start' m={1}>
          <span>cumpator AGROVIDA IMPEX SRL</span>
          <span>CIF/CNP/NIF: 26646022</span>
          <span>Numar de ordine in registrul comertului</span>
          <span>J4/234/2010</span>
        </Box>
        <Box display='flex' flexDirection='column' alignItems='flex-end' m={1}>
          <span>Localite: SLANIC MOLDOVA</span>
          <span>Str.DOBRU nr.14</span>
          <span>Jubetul:Bacau</span>
        </Box>
      </Footer>
    </MainWrapper>
  );
};

export default Polls;
