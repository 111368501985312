import styled from 'styled-components';
import {down} from 'styled-breakpoints';

export const Wrapper = styled.div`
  margin-right: 0em;
  margin-left: auto;
  display: inline-block;
  top: 5%;
  left: 1%;
  max-width: 100%;
  width: 15%;
  border: 2px solid #635d73;
  background-color: rgba(1, 1, 1, 0.3);
  border-radius: 0.3vw;
  padding: 10px;
  color: white;
  height: max-content;
  font-family: 'Colus';
  font-weight: normal;
  font-style: normal;

  h2 {
    margin-bottom: 0.5rem;
    font-weight: 500;
    line-height: 1.2;
    font-size: 120%;
  }

  ${down('md')} {
    display: none;
  }
`;

export const Jackpot = styled.p`
font-size: 100%;
  font-weight: bold;
  /* margin: 10% 0; */
  line-height: 2.4;
  color: ${props => props.color};
  ${down('lg')} {
    font-size: 90%;
  } 
`;
