import styled from 'styled-components';
import {down, up} from 'styled-breakpoints';

export const HeaderWrapper = styled.header`
  height: 16.5vh;
  position: relative;
  margin-bottom: 1vh;
  font-family: 'Colus';
  font-weight: normal;
  font-style: normal;

  ${down('sm')} {
    margin-bottom: 5vh;
  }

  .top {
    padding-top: 0;
    height: 16.6%;
    background: linear-gradient(180deg, #1b0a24, #010105);
  }

  .bot {
    height: max-content;
    span {
      font-size: 118%;
    }

    ${down('md')} {
      span {
        font-size: 100%;
      }
    }
    ${down('lg')} {
      span {
        font-size: 130%;
      }
    }
  }
`;

export const Lines = styled.div`
  border-top: 2px solid #635d73;
  border-bottom: 2px solid #c4a260;
  background: linear-gradient(
    180deg,
    #1d1525 8%,
    #635d73 8%,
    #635d73 25%,
    #2c1a41 25%,
    #110417 79%,
    #c79536 79%,
    #c79536 100%
  );
  position: relative;
  height: 14.2%;
  box-shadow: 0 2px 4px black;
`;

export const Logo = styled.div`
  position: absolute;
  top: 0;
  height: 100%;
  left: 33vw;
  z-index: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  pointer-events: none;

  img {
    height: 100%;
    width: auto;
    filter: drop-shadow(0 2px 4px black);
  }

  ${down('lg')} {
    img {
      height: 80%;
    }
  }

  ${down('sm')} {
    img {
      height: 60%;
    }
  }
`;

export const Button = styled.button`
  cursor: pointer;
  background: transparent ${(props) => (props.img ? `url(${props.img})` : '')};
  background-size: 100% 100%;
  background-position: center center;
  margin: 0 0;
  font-family: 'Colus';
  width: 165px;
  height: 4vh;
  font-size: 70%;
  font-weight: normal;
  font-style: normal;
  color: ${(props) => (props.disabled ? '#616161' : '#fff')};
  border: none;
  ${(props) =>
    !props.disabled &&
    `&:hover {
    filter: brightness(1.6)
  }`}

  ${up('lg')} {
    width: 25.57vh;
    height: 5vh;
    font-size: 1.8vmin;
  }

  ${up('xl')} {
    font-size: 100%;
    width: 220px;
    height: 5vh;
  }
`;
