import styled from 'styled-components';
import bottomBg from '../../assets/images/bg_btns.png';
export const Wrapper = styled.footer`
  height: 9.75vh;
  background: ${`url(${bottomBg})`};
  border-top-left-radius: 1vw;
  border-top-right-radius: 1vw;
`;

export const Button = styled.button`
  cursor: pointer;
  background-image: ${(props) => `url(${props.bg})`};
  background-color: transparent;
  background-size: 100% 100%;
  border: none;
  width: 14vw;
  height: 3.6vw;
  font-size: 1.5vw;
  color: #fff;
  outline: none;
  filter: ${(props) => props.disabled ? 'brightness(0.8)' : ''};

  ${(props) => !props.disabled && `&:hover {
    filter: brightness(1.3);
  }`}
`;
