import React from 'react';
import {Wrapper, Jackpot} from './styles';

const Jackpots = ({jackpots}) => {
  const getJackpot = (name) => {
    const jackpot = jackpots[name];

    return typeof jackpot === 'number' ? jackpot : false;
  };

  // console.log(typeof getJackpot('diamond') === 'number')
  return (
    <Wrapper>
      <h2>JACKPOT</h2>
      {jackpots && (
        <>
          {typeof getJackpot('diamond') === 'number' && <Jackpot color="#b9f2ff">
            {'diamond ' + Math.floor(getJackpot('diamond')/100)}
          </Jackpot>}
          {typeof getJackpot('gold') === 'number' && <Jackpot color="gold">{'gold ' + Math.floor(getJackpot('gold')/100)}</Jackpot>}
          {typeof getJackpot('silver') === 'number' && <Jackpot color="silver">{'silver ' + Math.floor(getJackpot('silver')/100)}</Jackpot>}
          {typeof getJackpot('bronze') === 'number' && <Jackpot color="#cd7f32">{'bronze ' + Math.floor(getJackpot('bronze')/100)}</Jackpot>}
        </>
      )}
    </Wrapper>
  );
};

export default Jackpots;
