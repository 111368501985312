import styled from 'styled-components';

const PollContainer = styled.div`
  padding: 40px 15px;
  justify-content: center;
  color: #fff;
  background-color: rgba(20, 4, 29, 0.65);
  border: 2px solid #635d73;
  position: relative;
  z-index: 1;
  width: 70%;
  margin: 0 auto;
  margin-top: 25px;

  .selected {
    color: grey;
    border: 1px solid #c79536;
  }

  @keyframes fadeIn {
    from {
      opacity: 0;
    }

    to {
      opacity: 1;
    }
  }

  .fadeIn {
    animation: fadeIn 1s ease-in-out;
  }

  .question-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
  }
`;

const QuestionContainer = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
`;

const AnswerButton = styled.button`
  width: 50%;
  background: transparent;
  cursor: pointer;
  font-size: 20px;
  margin: 0.5vh;
  padding: 2px 15px;
  transition: 0.3s;
  border-radius: 3px;
  border: 1px solid #edf9fc;
  color: #fff;
`;

export const SubmitAnswer = styled.button`
  cursor: pointer;
  background: transparent ${(props) => (props.img ? `url(${props.img})` : '')};
  background-size: 100%;
  margin: 4vh 0;
  width: 25.57vh;
  height: 5vh;
  font-size: 70%;
  color: ${(props) => (props.disabled ? '#616161' : '#fff')};
  border: none;
  ${(props) =>
    !props.disabled &&
    `&:hover {
    filter: brightness(1.6)
  }`}
`;
const Sum = styled.div`
  border-radius: 4px;
  font-size: 20px;
  width: 500px;
  text-align: center;
  background-color: rgba(20, 4, 29, 0.76);
  padding: 15px;
  @media (max-width: 1024px) {
    width: 100%;
  }
`;

const Subheading = styled.h4`
  font-weight: normal;
  font-size: 23px;
  line-height: 28px;
  text-align: center;
  padding: 2% 0;
  border-bottom: 2px solid #635d73;
  width: 75%;
  line-height: 37px;
  margin-bottom: 5%;
`;

export {PollContainer, AnswerButton, Sum, Subheading, QuestionContainer};
