export const theme = {
  breakpoints: {
    sm: '576px',
    md: '768px',
    lg: '1024px',
    xl: '1360px',
    xxl: '1600px',
  },
  colors: {
    navLinkBlue: '#0A5AAB',
    mainBlue: '#0E9AFC',
    darkBlue: '#031238',
    darkBlue1: '#03224D',
    white: '#ffffff',
    lightGrey: '#BFBFBF',
  },
  fontSizes: {
    text: '16px',
    blockTitle: '30px',
  },
  lineHeights: {
    text: '19px',
    blockTitle: '36px',
  },
  navLinkBoxShadow: '-4px -4px 0px #FFFFFF',
  tabBoxShadow: '-3px -3px 0px #0E9AFC',
  pressedBoxShadow: 'inset -4px -4px 4px rgba(0, 0, 0, 0.4)',
  blockShadow: '0px 0px 13px 2px rgba(14, 154, 252, 0.4)',
};
