import {AUTH_API} from '../settings';
import axios from 'axios'

const fetchInfo = async () => {
  const URI = `v1/info`;
  return await axios.get(URI)
};

const fetchRecords = async () => {
  const URI = `${AUTH_API}/json/highscores`;
  const query = {
    method: 'GET',
    credentials: 'include',
    // headers: {
    //   'Content-Type': 'application/json',
    // },
  };

  const response = await fetch(URI, query);
  const json = await response.json();
  return json;
};

const login = async (data) => {
  const URI = `/v1/auth/sign-in`;
  return await axios.post(URI, data) 
};


const collect = async () => {
  const URI = `${AUTH_API}/json/collect`;
  const query = {
    method: 'GET',
    credentials: 'include',
  };

  const response = await fetch(URI, query);
  const json = await response.json();
  return json;
};

const remoteIn = async (credits) => {
  const URI = `${AUTH_API}/json/requestMoney?sum=${credits}`;
  const query = {
    method: 'GET',
    credentials: 'include',
  };

  const response = await fetch(URI, query);
  const json = await response.json();
  return json;
};

export default {
  fetchInfo,
  login,
  fetchRecords,
  collect,
  remoteIn,
};
