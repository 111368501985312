import {configureStore} from '@reduxjs/toolkit';

import pollsReducer from './pollsReducer';
import authReducer from './authReducer';
import mainContentReducer from './mainContentReducer';
import denominationReducer from './denominationReducer';
import recordsReducer from './recordsReducer';
import gamesReducer from './gamesReducer'

export default configureStore({
  reducer: {
    polls: pollsReducer,
    auth: authReducer,
    mainContent: mainContentReducer,
    denomination: denominationReducer,
    records: recordsReducer,
    games: gamesReducer
  },
});
