import React from 'react';
import {Wrapper, Button} from './styles';
import {Box} from '@material-ui/core';

import {authSelector} from '../../redux/authReducer';

import back from '../../assets/images/btn_back.png';
import next from '../../assets/images/btn_next.png';
import rules from '../../assets/images/btn_rules.png';
import save from '../../assets/images/btn_save.png';
import {useSelector} from 'react-redux';

const GamesFooter = ({
  setCarouselValue,
  carouselValue,
  allGames,
  showRules,
  showRecords,
}) => {
  const {
    authInfo: {info},
  } = useSelector(authSelector);
  const isSocialPoll = info && info.is_social_poll;
  return (
    <Wrapper>
      <Box
        display="flex"
        justifyContent="space-around"
        alignItems="center"
        style={{height: '100%'}}
      >
        <Button
          bg={back}
          onClick={() => {
            carouselValue === 0
              ? setCarouselValue(allGames.length - 1)
              : setCarouselValue(carouselValue - 1);
          }}
        />
        {!!isSocialPoll && (
          <Button
            bg={save}
            disabled={!!(info?.balance === 0)}
            onClick={!!(info?.balance !== 0) ? showRecords : () => {}}
          />
        )}
        <Button bg={rules} onClick={showRules} />
        <Button
          bg={next}
          onClick={() => {
            carouselValue === allGames.length - 1
              ? setCarouselValue(0)
              : setCarouselValue(carouselValue + 1);
          }}
        />
      </Box>
    </Wrapper>
  );
};

export default GamesFooter;
