import React from 'react';
import { useSelector, useDispatch } from 'react-redux';

import {
  TableContainer,
  TableHeader,
  TableHeaderCell,
  Recommend,
  Title,
} from './styles';

import { Button } from '../Header/styles';
import button from 'assets/images/violet-button.png';

import { recordsSelector, saveRecords } from '../../redux/recordsReducer';
import {
  Overlay,
  ModalWindow,
  ModalTop,
  ModalBody,
  ModalBot,
} from 'components/Modal/styles';
import { useNavigate } from 'react-router-dom';
import { Box } from '@material-ui/core';
import Preloader from 'components/Common/Preloader';
import { ScrollBox } from 'components/Common/ScrollBox/styles';

const Records = ({ hideRecords }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  // const {
  //   records: { loading},
  // } = useSelector(authSelector);
  const {
    savedRecord: { data: items, loading },
  } = useSelector(recordsSelector);

  React.useEffect(() => {
    // dispatch(getRecords());
    dispatch(saveRecords());
  }, [dispatch]);



  return (
    <>
      {(
        <Overlay>
          <ModalWindow>
            <ModalTop />
            <ModalBody p={'6% 5%'}>
              <Box
                display="flex"
                flexDirection="column"
                justifyContent="center"
              >
                <Title>TАБЛИЦА РЕКОРДОВ ИГРЫ</Title>
                <TableContainer>
                  <TableHeader>
                    <TableHeaderCell>#</TableHeaderCell>
                    <TableHeaderCell>СТОЛ</TableHeaderCell>
                    <TableHeaderCell>РЕКОРД</TableHeaderCell>
                  </TableHeader>
                  <ScrollBox height={'63vh'}>
                    {loading ? (
                      <Preloader />
                    ) : (
                      items && !!items.length &&
                      items.map((item, index) => (
                        <TableHeader key={index}>
                          <TableHeaderCell>{index + 1}</TableHeaderCell>
                          <TableHeaderCell>{item.gambling_table_id}</TableHeaderCell>
                          <TableHeaderCell>{(item.balance_end / 100).toFixed(2)}</TableHeaderCell>
                        </TableHeader>
                      ))
                    )}
                  </ScrollBox>
                </TableContainer>
                <Recommend>Рекомендуем пройти социальный опрос</Recommend>
              </Box>
            </ModalBody>
            <ModalBot>
              <Button
                img={button}
                onClick={() => {
                  hideRecords();
                  navigate('/polls');
                }}
              >
                Пройти опрос
              </Button>
            </ModalBot>
          </ModalWindow>
        </Overlay>
      )}
    </>
  );
};

export default Records;
