import styled from 'styled-components';
import {down} from 'styled-breakpoints';

export const Wrapper = styled.div`
  background: linear-gradient(180deg, #1b0a24, #010105);
  font-size: 16pt;
  font-style: oblique;
  padding: 1%;
  color: white;
  margin-right: auto;
  margin-left: 0px;
  top: 5%;
  left: 1%;
  max-width: 100%;
  width: 15%;
  border: 2px solid #635d73;
  float: left;
  background-color: rgba(1, 1, 1, 0.3);
  border-radius: 0.3vw;
  padding: 10px;
  height: max-content;
  font-family: 'Colus';
  font-weight: normal;
  font-style: normal;

  ${down('md')} {
    display: none;
  } 
`;

export const Package = styled.label`
  cursor: pointer;
  display: inline-block;
  margin: 2.5% 0;
  font-size: 100%;
  font-style: oblique;
  font-weight: 400;
  line-height: 1.5;
  color: ${props => `${props.color}`};

  &:hover {
      color: pink;
  }

  ${down('lg')} {
    font-size: 70%;
  } 
`;
