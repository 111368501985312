import React from 'react';
import {Wrapper} from './styles';
import banner_first from '../../assets/images/first.jpg';
import banner_second from '../../assets/images/second.jpg';
import banner_third from '../../assets/images/third.jpg';

import Carousel, {autoplayPlugin} from '@brainhubeu/react-carousel';
import '@brainhubeu/react-carousel/lib/style.css';
import {Box} from '@material-ui/core';

const Banner = () => {
  const images = [banner_first, banner_second, banner_third];

  const renderItems = (array) => {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        style={{height: '100%'}}
      >
        <Carousel
          infinite
          draggable={false}
          plugins={[
            {
              resolve: autoplayPlugin,
              options: {
                interval: 2000,
              },
            },
          ]}
          autoPlay={10000}
          animationSpeed={2000}
        >
          {array && array.map((item, id) => <img key={id} src={item} alt="" />)}
        </Carousel>
      </Box>
    );
  };
  return (
    <Wrapper>
      <div className="container">{renderItems(images)}</div>
    </Wrapper>
  );
};

export default Banner;
