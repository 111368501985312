import React from 'react';
import { Wrapper, Game, GamesSlide } from './styles';
import GamesFooter from 'components/GamesFooter';
import { Grid, makeStyles } from '@material-ui/core';
import Carousel from '@brainhubeu/react-carousel';
import '@brainhubeu/react-carousel/lib/style.css';
import { splitSlidesData } from 'utils/helpers';

const gameIcons = {
  "millenium_age_of_heroes_deluxe": "age_of_heroes",
  "millenium_always_american_deluxe": "always_american",
  "millenium_always_hot": "always_hot",
  "millenium_always_hot_deluxe": "always_hot_deluxe",
  "millenium_amazon_treasures_deluxe": "amazon_treasures",
  "millenium_banana_splash": "banana_splash",
  "banana_splash_inbet": "banana_splash",
  "bananas_go_bahamas_inbet": "bananas_go_bahamas",
  "millenium_bananas": "bananas_go_bahamas",
  "beetle_mania_deluxe": "beetlee_mania_deluxe",
  "millenium_bloody_garage": "bloody_garage",
  "book_of_ra_inbet": "book_of_ra",
  "millenium_book_of_ra": "book_of_ra",
  "millenium_book_of_ra_deluxe": "book_of_ra_deluxe",
  "book_of_ra_deluxe_inbet": "book_of_ra_deluxe",
  "millenium_book_of_strike": "book_of_strike",
  "millenium_buzkashi_tadj": "buzkashi",
  "millenium_buzkashi_new": "buzkashi",
  "millenium_cocktail_football": "cocktail_football",
  "millenium_cocktail_football_tadj": "cocktail_football",
  "millenium_cocktail_football_ru": "cocktail_football",
  "millenium_cocktail_football_new": "cocktail_football",
  "cold_spell_inbet": "cold_spell",
  "millenium_cold_spell_deluxe": "cold_spell",
  "columbus_inbet": "columbus",
  "columbus_deluxe_inbet": "columbus_deluxe",
  "millenium_columbus": "columbus",
  "millenium_columbus_deluxe": "columbus_deluxe",
  "millenium_columbus_warship": "columbus_warship",
  "crazy_monkey": "crazy_monkey",
  "crazy_monkey_deluxe": "crazy_monkey2",
  "millenium_crazy_hockey": "crazy_of_hockey",
  "millenium_crazy_hockey_tadj": "crazy_of_hockey",
  "millenium_crazy_hockey_ru": "crazy_of_hockey",
  "millenium_crazy_hockey_new": "crazy_of_hockey",
  "millenium_dirty_money": "dirty_money",
  "dolphins_pearl_inbet": "dolphins_pearl",
  "millenium_dolphins_pearl": "dolphins_pearl",
  "millenium_egyptian_experience_deluxe": "egyptian_experience_deluxe",
  "emperors_china_inbet": "emperors_china",
  "millenium_emperors_china": "emperors_china",
  "faust_inbet": "faust",
  "millenium_faust_deluxe": "faust",
  "fruit_cocktail": "fruit_cocktail",
  "fruit_cocktail_deluxe": "fruit_cocktail2",
  "fruits_and_royals_inbet": "fruits_and_royals",
  "garage": "garage",
  "millenium_gate_of_ra_deluxe": "gate_of_ra",
  "gnome": "gnome",
  "millenium_gold_basketball": "gold_basketball",
  "millenium_gold_basketball_tadj": "gold_basketball",
  "millenium_gold_basketball_ru": "gold_basketball",
  "millenium_gold_basketball_new": "gold_basketball",
  "millenium_gold_craze_deluxe": "gold_craze_deluxe",
  "golden_ark_inbet": "golden_ark",
  "millenium_golden_ark_deluxe": "golden_ark",
  "gryphons_gold_inbet": "gryphons_gold",
  "millenium_gryphons_gold": "gryphons_gold",
  "millenium_hannibal_deluxe": "hannibal_deluxe",
  "millenium_haute_couture": "haute_couture",
  "millenium_holmes_watson_deluxe": "holmes_watson_deluxe",
  "illusionist_inbet": "illusionist",
  "millenium_illusionist": "illusionist",
  "island": "island2",
  "millenium_just_fruits_deluxe": "just_fruits",
  "just_jewels_inbet": "just_jewels",
  "millenium_just_jewels": "just_jewels",
  "just_jewels_deluxe_inbet": "just_jewels_deluxe",
  "millenium_just_jewels_deluxe": "just_jewels_deluxe",
  "keks": "keks",
  "khodjanasreddin": "khodja",
  "khodjanasreddinloto": "khodja",
  "khodja": "khodja",
  "khodjanasreddinlotosport": "khodja",
  "millenium_lord_of_the_ocean_deluxe": "lord_of_the_ocean",
  "millenium_lord_of_warcraft": "lord_of_warcraft",
  "millenium_lucky_dota": "lucky_dota",
  "lucky_haunter": "lucky_haunter",
  "millenium_lucky_lady_charm": "lucky_lady_charm",
  "marco_polo_inbet": "marco_polo",
  "marco_polo_deluxe_inbet": "marco_polo_deluxe",
  "millenium_marco_polo_deluxe": "marco_polo_deluxe",
  "pharaohs_gold_inbet": "pharaons_gold",
  "pirate": "pirate",
  "quake_mania": "quake_mania",
  "resident": "resident",
  "millenium_resident_of_nfl": "resident_nfl",
  "millenium_resident_of_nfl_tadj": "resident_nfl",
  "millenium_resident_of_nfl_ru": "resident_nfl",
  "millenium_resident_of_nfl_new": "resident_nfl",
  "rock_climber": "rock_climber",
  "royal_treasures_inbet": "royal_treasures",
  "millenium_royal_treasures": "royal_treasures",
  "millenium_royal_treasures_deluxe": "royal_treasures_deluxe",
  "suntechnikchess": "santechnik",
  "suntechnikloto": "santechnik",
  "sun_tech_walker": "santechnik",
  "suntechniklotosport": "santechnik",
  "millenium_secret_of_egypt_deluxe": "secret_of_egypt_deluxe",
  "millenium_shark_tanks": "shark_tanks",
  "millenium_silver_fox_deluxe": "silver_fox",
  "millenium_sizzling_crown_deluxe": "sizzling_crown_deluxe",
  "millenium_sizzling_eight_deluxe": "sizzling_eight_deluxe",
  "millenium_sizzling_gems_deluxe": "sizzling_gems_deluxe",
  "millenium_sizzling_hot7_deluxe": "sizzling_hot7_deluxe",
  "millenium_starcraft_pearl": "starcraft_pearl",
  "sweet_life": "sweet_life",
  "millenium_the_ming_dynasty_deluxe": "the_ming_dynasty_deluxe",
  "the_money_game_inbet": "the_money_game",
  "millenium_money_game_deluxe": "the_money_game_deluxe",
  "millenium_money_game": "the_money_game",
  "alcatraz": "escape",
  "fairy_land": "fairy_land",
  "lucky_drink": "lucky_drink",
  "arctic_treasure": "arctic_treasure",
  "azteca": "azteca",
  "captains_treasure": "captains_treasure",
  "cherry_love": "cherry_love",
  "diamond_valley": "diamond_valley",
  "discovery": "the_discovery",
  "golden_games": "golden_games",
  "great_blue": "great_blue",
  "hulk": "hulk",
  "hulk50": "hulk2",
  "irish_luck": "irish_luck",
  "iron_man": "iron_man",
  "iron_man2": "iron_man2",
  "panther_moon": "panther_moon",
  "penguin_vacation": "penguin_vacation",
  "rome_and_glory": "rome_and_glory",
  "russian_love": "from_russia",
  "safari_heat": "safari_heat",
  "skazka": "skazka",
  "sparta": "sparta",
  "thai_paradise": "thai_paradise",
  "true_love": "true_love",
  "wild_spirit": "world_spirit",
  "book_of_ra6": "book_of_ra_6-deluxe",
  "fairy_queen_deluxe": "fairy_queen",
  "fruit_sensation_deluxe": "fruit_sensation",
  "hot_chance_deluxe": "hot_chance-deluxe",
  "jackpot_crown_deluxe": "jackpot_crown",
  "lucky_rose_deluxe": "lucky_rose",
  "olivers_bar": "oliver_bar",
  "party_games_slotto_deluxe": "party_games",
  "pharaohs_rings_deluxe": "pharaohs_ring",
  "keno": "keno_live",
  "endorphina_cash_tank": "cash_tank",
  "endorphina_chance_machine5": "endorphina_chance_machine5",
  "endorphina_chance_machine20": "endorphina_chance_machine20",
  "endorphina_gem_blast": "endorphina_gem_blast",
  "endorphina_diamond_chance": "endorphina_diamond_chance",
  "endorphina_voodoo": "endorphina_voodoo",
  "endorphina_voodoo_dice": "endorphina_voodoo_dice",
  "endorphina_in_jazz": "endorphina_in_jazz",
  "endorphina_joker_stoker": "endorphina_joker_stoker",
  "endorphina_solar_eclipse": "endorphina_solar_eclipse",
  "endorphina_chance_machine5_dice": "endorphina_chance_machine5_dice",
  "diamond_trio": "diamond_trio",
  "flame_dancer_deluxe": "flame_dancer",
  "mystic_secrets_deluxe": "mystic_secrets",
  "sharky": "sharky",
	"roulette_mob": "roulette_mob",
	"roulette_with_track_mob": "roulette_with_track_mob",
	"millenium_sinbad": "millenium_sinbad",
	"millenium_masha_and_bear": "millenium_masha_and_bear",
	"millenium_3wishes": "millenium_3wishes",
	"millenium_gnoms_mine": "millenium_gnoms_mine",
	"millenium_hot_strike": "millenium_hot_strike",
	"millenium_hotter_ace": "millenium_hotter_ace",
	"millenium_india_night": "millenium_india_night",
	"millenium_lucky_menu": "millenium_lucky_menu",
	"millenium_lucky_saloon": "millenium_lucky_saloon",
	"millenium_pharaohs_artifact": "millenium_pharaohs_artifact",
	"millenium_rabbit_illusion": "millenium_rabbit_illusion",
	"millenium_shaolino": "millenium_shaolino",
	"millenium_aladdin": "millenium_aladdin",
	"millenium_arabian_delight": "millenium_arabian_delight",
	"millenium_chicago": "millenium_chicago",
	"millenium_circus_magic": "millenium_circus_magic",
	"millenium_flame_jocker": "millenium_flame_jocker",
	"millenium_monkey_island": "millenium_monkey_island",
	"millenium_fruit_show": "millenium_fruit_show",
  "millenium_fairy_forest": "millenium_fairy_forest",
  "millenium_dolphins_lagoon": "millenium_dolphins_lagoon",
	"millenium_arctic_gold": "millenium_arctic_gold",
  "american_poker_2_mob": "american_poker_2_mob",
  "inbet_irish_story_keno": "inbet_irish_story_keno",
  "inbet_irish_story_3x3_pull_tabs": "inbet_irish_story_3x3_pull_tabs",
  "inbet_diamond_rich_life_3x3": "inbet_diamond_rich_life_3x3",
  "inbet_buffalo_spirit_3x3_pull_tabs": "inbet_buffalo_spirit_3x3_pull_tabs",
  "inbet_haunted_money_3x3_pull_tabs": "inbet_haunted_money_3x3_pull_tabs",
  "inbet_irish_story_3x3": "inbet_irish_story_3x3",
  "inbet_diamond_rich_life_3x3_pull_tabs": "inbet_diamond_rich_life_3x3_pull_tabs",
  "inbet_haunted_money_3x3": "inbet_haunted_money_3x3",
  "inbet_buffalo_spirit_3x3": "inbet_buffalo_spirit_3x3",
};

const useStyles = makeStyles(() => ({
  container: {
    height: '100%',
    display: 'flex',
    alignContent: 'start',
  },
  item: {
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '33%',
  },
}));

const Games = ({
  setCarouselValue,
  carouselValue,
  games,
  showRules,
  showRecords,
}) => {
  const classes = useStyles();
  const allGames = games && splitSlidesData(games, 12);

  return (
    <Wrapper style={{ margin: 0 }}>
      <Carousel infinite draggable={false} value={carouselValue}>
        {allGames &&
          allGames.map((slide, id) => {
            return (
              <GamesSlide key={id}>
                <Grid container className={classes.container}>
                  {slide.map((game, game_id) => {
                    const id = game.image.match(/\/([^/]+)\.png$/);
                    return <Grid key={game_id} item xs={3} className={classes.item}>
                      <Game onClick={() => game.status ? (window.location.href = `${game.link}?token=${localStorage.getItem('token')}`) : console.log(game)}>
                        <img src={id && gameIcons[id[1]] ? require(`../../assets/images/games/${gameIcons[id[1]]}.png`) : game.image} alt={game.name} />
                      </Game>
                    </Grid>
                  })}
                </Grid>
              </GamesSlide>
            );
          })}
      </Carousel>
      <GamesFooter
        setCarouselValue={setCarouselValue}
        carouselValue={carouselValue}
        allGames={allGames}
        showRules={showRules}
        showRecords={showRecords}
      />
    </Wrapper>
  );
};

export default Games;
