import styled from 'styled-components';
import {down} from 'styled-breakpoints';
import play_button from '../../assets/images/play-button.png';

export const Wrapper = styled.div`
  width: 70%;
  margin: 0 auto;
  max-height: 100%;
  margin: auto;
  height: 100%;

  ${down('md')} {
    width: 100%;
  }
`;

export const GamesSlide = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: center;
  height: 56.5vh;
  width: 100%;
  overflow: hidden;
`;

export const Game = styled.div`
  cursor: pointer;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  user-select: none;
  overflow: hidden;
  img {
    width: 95%;
    height: 95%;
  }

  :after {
    content: 'Играть сейчас';
    font-size: 80%;
    font-weight: bold;
    text-transform: uppercase;
    white-space: nowrap;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    position: absolute;
    transform: translate(-50%, -50%);
    border-radius: 5vh;
    top: 50%;
    left: 50%;
    width: max-content;
    padding: 0 5px;
    min-width: 80px;
    height: 30%;
    background: -webkit-linear-gradient(
        315deg,
        hsla(307.55, 67.8%, 38.71%, 1) 0%,
        hsla(307.55, 67.8%, 38.71%, 0) 70%
      ),
      -webkit-linear-gradient(65deg, hsla(284.91, 54.55%, 14.89%, 1) 10%, hsla(
              284.91,
              54.55%,
              14.89%,
              0
            )
            80%),
      -webkit-linear-gradient(135deg, hsla(288.58, 96.87%, 41.75%, 1) 15%, hsla(
              288.58,
              96.87%,
              41.75%,
              0
            )
            80%),
      -webkit-linear-gradient(205deg, hsla(291.51, 96.12%, 46.43%, 1) 100%, hsla(
              291.51,
              96.12%,
              46.43%,
              0
            )
            70%);
    background: linear-gradient(
        135deg,
        hsla(307.55, 67.8%, 38.71%, 1) 0%,
        hsla(307.55, 67.8%, 38.71%, 0) 70%
      ),
      linear-gradient(
        25deg,
        hsla(284.91, 54.55%, 14.89%, 1) 10%,
        hsla(284.91, 54.55%, 14.89%, 0) 80%
      ),
      linear-gradient(
        315deg,
        hsla(288.58, 96.87%, 41.75%, 1) 15%,
        hsla(288.58, 96.87%, 41.75%, 0) 80%
      ),
      linear-gradient(
        245deg,
        hsla(291.51, 96.12%, 46.43%, 1) 100%,
        hsla(291.51, 96.12%, 46.43%, 0) 70%
      );
    opacity: 0;
    transition: 0.4s;
  }

  :hover:after {
    filter: drop-shadow(0px 0px 10px rgba(255,255,255,1));
    opacity: 1;
  }

  ${down('md')} {
    :after {
      font-size: 60%;
    }
  }
`;

export const PlayBtn = styled.button`
  cursor: pointer;
  position: absolute;
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
  text-decoration: none;
  margin: auto;
  display: block;
  width: 135px;
  height: 50px;
  border: none;
  background-color: transparent;
  background-image: ${`url(${play_button})`};
  background-position: top center;
  background-repeat: no-repeat;
  font-family: revert;
  font-size: 70%;
  font-weight: bold;
  text-align: center;
  text-transform: uppercase;
  line-height: 50px;
  color: #fff;

  &:hover {
    filter: brightness(1.3);
    background-position: bottom center;
  }
`;
