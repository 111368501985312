import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  min-height: 400px;
  padding: 40px 15px;
  background-color: rgba(20, 4, 29, 0.65);
  border: 2px solid #635d73;
  color: #fff;
  z-index: 1;
  width: 70%;
  margin: 0 auto;
  margin-top: 25px;
`;

const AcceptConditionsText = styled.p`
  font-family: 'Candara', sans-serif;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 21px;
  margin-top: 5px;
  @media (max-width: 900px) {
    font-size: 14px;
    flex-wrap: wrap;
  }
`;

const ConditionsLink = styled.a`
  color: white;
  text-decoration: underline;
  cursor: pointer;
  margin-left: 5px;
`;

export {Container, AcceptConditionsText, ConditionsLink};
