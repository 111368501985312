import styled from 'styled-components';

export const Title = styled.h2`
  font-size: 150%;
  margin-bottom: 1rem;
`;
export const Text = styled.p`
  font-size: 80%;
  margin-top: 0;
  margin-bottom: 1rem;
`;
