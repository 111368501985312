import React from 'react';
import { Wrapper, Package } from './styles';
import { Box } from '@material-ui/core';

const GamePackages = ({ games, selectedProvider, setSelectedProvider, setCarouselValue }) => {
  const getGamePackages = (games) => {
    let providers = []
    games.forEach(item => {
      providers = [...providers, item.package.name]
    })
    return providers
  }

  return (
    <Wrapper style={{ maxHeight: "66vh", overflow: "hidden auto" }}>
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="flex-start"
      >
        <Package
          onClick={() => {
            setCarouselValue(0);
            setSelectedProvider('');
          }}
          color={!selectedProvider ? 'red' : 'inherit'}
        >
          All Games
        </Package>
        {games &&
          getGamePackages(games).map((item, id) => {
            return (
              <Package
                key={id}
                onClick={() => {
                  setCarouselValue(0);
                  setSelectedProvider(item);
                }}
                color={selectedProvider === item ? 'red' : ''}
              >
                {item}
              </Package>
            );
          })}
      </Box>
    </Wrapper>
  );
};

export default GamePackages;
