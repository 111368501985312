import styled from 'styled-components';
import { down } from 'styled-breakpoints';

export const Wrapper = styled.div`
  position: relative;
  background-color: #14041d;
  border-bottom: 2.5px solid #635d73;
  border-top: 2.5px solid #635d73;
  height: 16vh;
  overflow: hidden;

  ${down('sm')} {
    height: 12vh;
  }
  
  img {
    height: auto;
    width: 100%;
  }
`;
