import {createSlice} from '@reduxjs/toolkit';
import { fetchGamePackages } from 'services/games';

export const slice = createSlice({
  name: 'games',
  initialState: {
    games: [],
    loading: false,
    error: null,
  },
  reducers: {
    gamesIsLoading: (state) => {
      state.loading = true;
    },
    gamesSuccess: (state, {payload}) => {
      state.games = payload;
      state.loading = false;
      state.error = null;
    },
    gamesFailure: (state, {payload}) => {
      state.loading = false;
      state.error = payload;
    },
  },
});

export function getGames() {
  return async (dispatch) => {
    dispatch(gamesIsLoading());

    fetchGamePackages().then((res) => {
      if (res.data.code === 200) {
        dispatch(gamesSuccess(res.data.data));
      } else {
        dispatch(gamesFailure(res.data.data));
      }
    });
  };
}

export const {gamesIsLoading, gamesSuccess, gamesFailure} = slice.actions;

export const gamesSelector = (state) => state.games;

export default slice.reducer;
