import React from 'react';
import { HeaderWrapper, Logo, Lines, Button } from './styles';

import logo from '../../assets/images/logo.png';
import { Box, makeStyles, NativeSelect } from '@material-ui/core';
import button from 'assets/images/violet-button.png';
import buttonGold from 'assets/images/gold-button.png';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { changeDenomination } from 'redux/denominationReducer';
import { authSelector } from 'redux/authReducer';

const useStyles = makeStyles((theme) => ({
  denom: {
    margin: theme.spacing(1),
    outline: 'none',
    '& .MuiNativeSelect-icon': {
      color: '#fff',
    },
    '&.MuiNativeSelect-input': {
      outline: 'none',
    },
  },
}));

const Header = ({
  balance,
  denomination,
  denominations,
  showOferta,
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const classes = useStyles();

  const {
    authInfo: { info },
  } = useSelector(authSelector);
  const isSocialPoll = info && info.is_social_poll;
  return (
    <HeaderWrapper>
      <div className="top"></div>
      <Lines />
      <Logo>
        <img src={logo} alt="logo" />
      </Logo>
      <div className="bot">
        <div className="container">
          <Box display="flex" justifyContent="space-between">
            <Box
              display="flex"
              flexDirection="column"
              justifyContent="flex-end"
              alignItems="flex-start"
              m={2}
            >
              <span>ОЧКИ: {Math.floor(balance / 100)}</span>
              <Box display="flex" alignItems="center">
                <span>ДЕНОМИНАЦИЯ: </span>
                <NativeSelect
                  disableUnderline
                  value={denomination ? denomination : ''}
                  onChange={(e) =>
                    dispatch(changeDenomination({ denomination: e.target.value }))
                  }
                  name="age"
                  className={classes.denom}
                  inputProps={{
                    style: {
                      color: '#fff',
                      fontFamily: 'Colus',
                      outline: 'none',
                    },
                  }}
                  style={{ outline: 'none' }}
                >
                  {denominations &&
                    denominations.map((option) => (
                      <option
                        key={option}
                        value={option}
                        style={{ background: '#2c1a41', color: '#fff' }}
                      >
                        {option / 100}
                      </option>
                    ))}
                </NativeSelect>
              </Box>
            </Box>
            <Box
              display="flex"
              justifyContent="flex-end"
              alignItems="center"
              flexWrap="wrap"
              m={1}
              style={{ maxWidth: 400 }}
            >
              {!!isSocialPoll && (
                <Button
                  img={button}
                  onClick={() => {
                    navigate('/polls');
                  }}
                >
                  ПРОЙТИ ОПРОС
                </Button>
              )}
              <Button img={buttonGold} onClick={showOferta}>
                ПУБЛИЧНАЯ ОФЕРТА
              </Button>
            </Box>
          </Box>
        </div>
      </div>
    </HeaderWrapper>
  );
};

export default Header;
